<template>
  <div class="tariffs-page">
    <div class="tariffs-page__top">
      <tariffs-result
        :totalCost="totalCost"
        :serviceCost="customsServiceResult"
        :onOpenMore="() => (isDetailOpened = true)"
      />
    </div>
    <transition name="fast-fade" mode="out-in">
      <div key="1" v-if="currStep === 1" class="tariffs-page__bottom">
        <div class="tariffs-calculator">
          <!-- <tariffs-tabs
            :currentTab.sync="country"
            :deliveryRates="deliveryRates"
          /> -->
          <tariffs-tabs
            :currentTab="userCountry"
            :deliveryRates="deliveryRates"
            :pricePerKG="deliveryRate"
          />
          <div class="tariffs-calculator__content">
            <tariffs-input
              :value.sync="goodsPrice"
              :title="$t('productCostPayPu')"
              :min="0"
              :max="maxProductPrice"
              :onTapValue="onPriceEditOpen"
            />
            <tariffs-input
              :value.sync="goodsWeigth"
              :title="$t('cardEntityWeight')"
              :sliderData="weightSliderData"
              :onTapValue="onWeigthEditOpen"
              :minWeigthCost="minWeigthCost"
            />
            <tariffs-order
              :isActive.sync="isBuyout"
              :buyoutCost="buyoutService"
              :buyoutPercent="buyoutPercent"
            />
            <button class="btnMain green" @click="currStep = 2">
              {{ $t("nextStep") }}
            </button>

            <div class="tarrifs-price-grid">
              <!-- <div class="tarrifs-price-grid__title">
                {{ $t("priceGridTitle") }}
              </div> -->
              <div class="tarrifs-price-grid__header">
                <span>
                  {{ $t("weight") }}
                </span>
                <b> {{ $t("cost") }} / 1kg </b>
              </div>
              <div
                class="tarrifs-price-grid__item"
                :class="{
                  active: goodsWeigth < rate.to && goodsWeigth >= rate.from,
                }"
                v-for="rate in deliveryGrid"
                :key="rate.from"
              >
                <span class="tarrifs-price-grid__from">
                  &lt; {{ rate.to }} kg
                </span>

                <b class="tarrifs-price-grid__price">${{ rate.price }}</b>
              </div>
            </div>
            <div @click="downloadPricesAsPDF" class="tarrifs-price-download">
              <span>{{ $t("savePriceAsPdf") }}</span>
            </div>
          </div>
        </div>
      </div>
      <div key="2" v-if="currStep === 2" class="tariffs-page__bottom">
        <div class="tariffs-calculator">
          <div class="tariffs-calculator__header">
            <div class="tariffs-calculator__back" @click="currStep = 1">
              <img src="@/assets/img/arr-b.svg" />
            </div>
            {{ $t("cardAdditionalServiceName") }}
          </div>
          <div class="tariffs-calculator__content tariffs-service__container">
            <div
              class="tariffs-service"
              @click="() => (isInsuranceEnabled = !isInsuranceEnabled)"
              :class="{ isActive: isInsuranceEnabled }"
            >
              <div class="tariffs-service__success-icon">
                <img src="@/assets/img/i-success.svg" />
              </div>
              <div class="tariffs-service__img">
                <img src="@/assets/img/ensuranceService.svg" />
              </div>
              <div class="tariffs-service__title">
                {{ $t("cardAdditionalServiceInsurance") }}
              </div>
              <div class="tariffs-service__cost">{{ insuranceFormatted }}</div>
            </div>
            <div
              class="tariffs-service"
              @click="() => (isPhotoEnabled = !isPhotoEnabled)"
              :class="{ isActive: isPhotoEnabled }"
            >
              <div class="tariffs-service__success-icon">
                <img src="@/assets/img/i-success.svg" />
              </div>
              <div class="tariffs-service__img">
                <img src="@/assets/img/photoService.svg" />
              </div>
              <div class="tariffs-service__title">
                {{ $t("addPhotoServiceTitle") }}
              </div>
              <div class="tariffs-service__cost">${{ photoPrice }}</div>
            </div>
            <div
              class="tariffs-service"
              @click="() => (isRepackEnabled = !isRepackEnabled)"
              :class="{ isActive: isRepackEnabled }"
            >
              <div class="tariffs-service__success-icon">
                <img src="@/assets/img/i-success.svg" />
              </div>
              <div class="tariffs-service__img">
                <img src="@/assets/img/repackService.svg" />
              </div>
              <div class="tariffs-service__title">
                {{ $t("addPackageServiceTitle") }}
              </div>
              <div class="tariffs-service__cost">${{ repackPrice }}</div>
            </div>
            <div
              class="tariffs-service"
              @click="() => (isCombineEnabled = !isCombineEnabled)"
              :class="{ isActive: isCombineEnabled }"
            >
              <div class="tariffs-service__success-icon">
                <img src="@/assets/img/i-success.svg" />
              </div>
              <div class="tariffs-service__img">
                <img src="@/assets/img/combineService.svg" />
              </div>
              <div class="tariffs-service__title">
                {{ $t("cardAdditionalServiceCombine") }}
              </div>
              <div class="tariffs-service__cost">${{ combinePrice }}</div>
            </div>
            <div
              class="tariffs-service"
              @click="() => (isDivideEnabled = !isDivideEnabled)"
              :class="{ isActive: isDivideEnabled }"
            >
              <div class="tariffs-service__success-icon">
                <img src="@/assets/img/i-success.svg" />
              </div>
              <div class="tariffs-service__img">
                <img src="@/assets/img/divideService.svg" />
              </div>
              <div class="tariffs-service__title">
                {{ $t("cardAdditionalServiceDivide") }}
              </div>
              <div class="tariffs-service__cost">${{ dividePrice }}</div>
            </div>
            <div
              class="tariffs-service"
              @click="() => (isConsultEnabled = !isConsultEnabled)"
              :class="{ isActive: isConsultEnabled }"
            >
              <div class="tariffs-service__success-icon">
                <img src="@/assets/img/i-success.svg" />
              </div>
              <div class="tariffs-service__img">
                <img src="@/assets/img/consultService.svg" />
              </div>
              <div class="tariffs-service__title">
                {{ $t("paymentConsultTitle") }}
              </div>
              <div class="tariffs-service__cost">{{ consultFormatted }}</div>
            </div>
          </div>
        </div>
        <button @click="onFinish" class="btnWideRound is-black">
          {{ isBuyout ? $t("addShopping") : $t("addParcel") }}
        </button>
      </div>
    </transition>

    <easy-bottom-sheet
      :isOpened.sync="isPriceOpened"
      :onClose="onPriceEditClose"
      :title="$t('editPriceGoodTitle')"
    >
      <easy-input
        :mask="numberMask"
        v-model.number="editablePrice"
        :label="$t('editPriceGoodField')"
        :errorText="$t('editPriceGoodError', { val: maxProductPrice })"
        :isError="isPriceError"
      ></easy-input>

      <template v-slot:footer>
        <button
          @click="onPriceEditSubmit"
          class="btnWideRound"
          :disabled="isPriceError"
        >
          {{ $t("btnSave") }}
        </button>
      </template>
    </easy-bottom-sheet>
    <easy-bottom-sheet
      :isOpened.sync="isWeightOpened"
      :onClose="onWeigthEditClose"
      :title="$t('editWeigthGoodTitle')"
    >
      <easy-input
        :mask="numberMask"
        v-model.number="editableWeigth"
        :label="$t('editWeigthGoodField')"
        :errorText="$t('editWeigthGoodError')"
        :isError="isWeightError"
      ></easy-input>

      <template v-slot:footer>
        <button
          @click="onWeigthEditSubmit"
          class="btnWideRound"
          :disabled="isWeightError"
        >
          {{ $t("btnSave") }}
        </button>
      </template>
    </easy-bottom-sheet>

    <tariffs-check
      :pricesData="pricesData"
      :isDetailOpened.sync="isDetailOpened"
      :toPayCount="totalCost"
    />
  </div>
</template>

<script>
import TariffsResult from "./TariffsResult.vue";
import TariffsOrder from "./TariffsOrder.vue";
import TariffsTabs from "./TariffsTabs.vue";
import TariffsInput from "./TariffsInput.vue";
import TariffsCheck from "./TariffsCheck.vue";
import EasyBottomSheet from "@/components/common/EasyBottomSheet.vue";
import EasyInput from "@/components/common/EasyInput.vue";
import { mapGetters } from "vuex";
import { openExternal } from "../../utils/utils";

const apiURL = process.env.VUE_APP_API_URL;

export default {
  name: "Tariffsv2",
  components: {
    TariffsResult,
    TariffsOrder,
    TariffsTabs,
    TariffsInput,
    TariffsCheck,
    EasyBottomSheet,
    EasyInput,
  },
  data() {
    return {
      // country: 284, // 284,285
      goodsPrice: 0,
      goodsWeigth: 1,
      editablePrice: 0,
      editableWeigth: 0,
      isBuyout: false,
      currStep: 1,
      isWeightOpened: false,
      isPriceOpened: false,
      isInsuranceEnabled: false,
      isPhotoEnabled: false,
      isRepackEnabled: false,
      isConsultEnabled: false,
      isCombineEnabled: false,
      isDivideEnabled: false,

      weightSliderData: [
        0,
        0.05,
        0.1,
        0.3,
        0.5,
        0.7,
        1,
        1.5,
        2,
        3.5,
        5,
        8,
        10,
        15.44,
        20.0,
        25.0,
        30.0,
        40,
        50,
      ],

      isDetailOpened: false,

      numberMask: {
        mask: "NNNNN",
        tokens: {
          N: { pattern: /^[0-9]*\.?[0-9]*$/ },
        },
      },
    };
  },
  computed: {
    ...mapGetters("root", [
      "buyoutPercent",
      "minBuyoutService",
      "dutyFreeLimit",
      "freeTaxLimit",
      "customsService",
      "deliveryRates",
      "deliveryRatesGrid",
      "insurancePercent",
      "insuranceMinCost",
      "dividePrice",
      "combinePrice",
      "consultPrice",
      "repackPrice",
      "photoPrice",
    ]),
    ...mapGetters("user", ["userCountry", "userCountryCode"]),
    deliveryGrid() {
      return this.deliveryRatesGrid[this.userCountryCode];
    },
    deliveryRate() {
      const { price } =
        this.deliveryGrid.find(({ to }) => this.goodsWeigth < to) ??
        this.deliveryGrid[0];

      return price;
    },
    isWeightError() {
      return this.editableWeigth > 50;
    },
    isPriceError() {
      return this.editablePrice > this.maxProductPrice;
    },
    buyoutService() {
      if (!this.isBuyout) return 0;
      const cost = (this.goodsPrice * this.buyoutPercent) / 100;
      return cost > this.minBuyoutService ? cost : this.minBuyoutService;
    },
    dutyTax() {
      if (this.goodsPrice - this.dutyFreeLimit > 0)
        return (this.goodsPrice - this.dutyFreeLimit) * 0.1;
      else return 0;
    },
    additionalTax() {
      if (this.goodsPrice - this.freeTaxLimit > 0)
        return (this.goodsPrice - this.freeTaxLimit + this.dutyTax) * 0.2;
      else return 0;
    },
    customsServiceResult() {
      if (this.dutyTax + this.additionalTax > 0)
        return this.dutyTax + this.additionalTax + this.customsService;
      else return 0;
    },
    minWeigthCost() {
      // if (this.userCountry === 285) return 0.5 * this.deliveryRate;
      // if (this.userCountry === 284) return 1 * this.deliveryRate;
      return this.deliveryRate;
      // if (this.country === 294) return 0.5 * this.deliveryRates.TK;
      // return 0;
    },
    globalDelivery() {
      const weight =
        this.goodsWeigth < this.minWeightPriceByCountry
          ? this.minWeightPriceByCountry
          : this.goodsWeigth;
      // if (this.userCountry === 285) return weight * this.deliveryRates.UA;
      // else if (this.userCountry === 284) return weight * this.deliveryRates.USA;

      return weight * this.deliveryRate;
      // else if (this.country === 294) return weight * this.deliveryRates.TK;
      // else return 0;
    },
    minWeightPriceByCountry() {
      if (this.userCountry === 285) return 0.5;
      if (this.userCountry === 284) return 1;
      // if (this.country === 294) return 0.5 * this.deliveryRates.TK;
      return 0;
    },
    consultCost() {
      return this.$options.filters.numberRound(
        (this.goodsPrice / 100) * this.consultPrice
      );
    },
    insuranceCost() {
      const insurancePrice = this.$options.filters.numberRound(
        (this.goodsPrice / 100) * this.insurancePercent
      );

      if (insurancePrice < this.insuranceMinCost) {
        return this.insuranceMinCost;
      }

      return insurancePrice;
    },
    consultFormatted() {
      return this.isConsultEnabled
        ? `$${this.consultCost}`
        : `${this.consultPrice}%`;
    },
    insuranceFormatted() {
      return this.isInsuranceEnabled
        ? `$${this.insuranceCost}`
        : `${this.insurancePercent}%`;
    },
    maxProductPrice() {
      if (this.userCountry === 284) {
        return 800;
      }

      return 1000;
    },
    pricesData() {
      return {
        werehouse: {
          Cost: 0,
        },
        countryDelivery: {
          Cost: this.globalDelivery,
        },
        insurance: {
          Cost: this.isInsuranceEnabled ? this.insuranceCost : 0,
        },
        combine: {
          Cost: this.isCombineEnabled ? this.combinePrice : 0,
        },
        divide: {
          Cost: this.isDivideEnabled ? this.dividePrice : 0,
        },
        productTax: {
          Cost: this.customsServiceResult,
        },
        additionalBox: {
          Cost: this.isRepackEnabled ? this.repackPrice : 0,
        },
        additionalPhoto: {
          Cost: this.isPhotoEnabled ? this.photoPrice : 0,
        },
        buyout: {
          Cost: this.isBuyout ? this.buyoutService : 0,
        },
        consult: {
          Cost: this.isConsultEnabled ? this.consultCost : 0,
        },
        product: {
          Cost: this.goodsPrice,
        },
      };
    },
    additionalServiceCost() {
      return (
        this.pricesData.consult.Cost +
        this.pricesData.combine.Cost +
        this.pricesData.divide.Cost +
        this.pricesData.insurance.Cost +
        this.pricesData.additionalBox.Cost +
        this.pricesData.additionalPhoto.Cost
      );
    },
    totalCost() {
      return (
        this.goodsPrice +
        this.globalDelivery +
        this.customsServiceResult +
        this.buyoutService +
        this.additionalServiceCost
      );
    },
    userPricePDF() {
      const list = {
        USA: `${apiURL}/media/price/PriceCN-US.pdf`,
        UA: `${apiURL}/media/price/PriceCN-UA.pdf`,
        GB: `${apiURL}/media/price/PriceCN-GB.pdf`,
        CA: `${apiURL}/media/price/PriceCN-CA.pdf`,
      };
      return list[this.userCountryCode];
    },
  },
  methods: {
    onFinish() {
      this.$router.push(this.isBuyout ? "add-buyout" : "add-delivery");
    },
    onWeigthEditOpen() {
      this.isWeightOpened = true;
      this.editableWeigth = this.goodsWeigth;
    },
    onWeigthEditClose() {
      this.isWeightOpened = false;
      this.editableWeigth = this.goodsWeigth;
    },
    onWeigthEditSubmit() {
      this.isWeightOpened = false;
      // const result = this.weightSliderData.reduce((prev, curr) => Math.abs(curr - this.editableWeigth) < Math.abs(prev - this.editableWeigth) ? curr : prev);
      this.goodsWeigth = +(Math.ceil(this.editableWeigth * 20) / 20).toFixed(2);
    },
    onPriceEditOpen() {
      this.isPriceOpened = true;
      this.editablePrice = this.goodsPrice;
    },
    onPriceEditClose() {
      this.isPriceOpened = false;
      this.editablePrice = this.goodsPrice;
    },
    onPriceEditSubmit() {
      this.isPriceOpened = false;
      this.goodsPrice = this.editablePrice || 0;
    },
    downloadPricesAsPDF() {
      openExternal(this.userPricePDF);
    },
  },
};
</script>

<style lang="scss">
.header-tariffs {
  display: none;
}
.tariffs-page {
  position: relative;
  padding-bottom: 0px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin-left: -16px;
  margin-right: -16px;
  margin-top: -84px;

  &__top {
    background: #282828;
    padding-bottom: 120px;
    padding-top: 16px;
    padding-left: 16px;
    padding-right: 16px;
    position: relative;
    z-index: 2;

    &:after {
      display: block;
      content: "";
      left: 0;
      right: 0;
      width: 100%;
      height: 400px;
      top: -300px;
      background: #282828;
      position: absolute;
      z-index: -1;
    }
  }

  &__bottom {
    flex: 1 auto;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 20px;
    position: relative;
    z-index: 3;
  }

  .tariffs-calculator {
    background: #fff;
    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin-top: -76px;
    min-height: 360px;
    margin-bottom: 26px;

    &__content {
      padding: 16px;
    }

    &__header {
      text-align: center;
      font-family: "Helvetica Neue Medium";
      position: relative;
      padding-top: 14px;
    }

    &__back {
      position: absolute;
      height: 50px;
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      left: 0;
      top: 0;
    }

    .btnMain {
      width: 100%;
      transition: 0.4s;

      &:disabled {
        opacity: 0.7;
      }
    }
  }
  .tarrifs-price-grid {
    padding-top: 16px;
    padding-bottom: 8px;
    text-align: center;
    color: #bfbfbf;
    position: relative;

    &__title {
      margin-bottom: 8px;
      font-size: 14px;
    }

    &__header {
      display: flex;
      border-bottom: 1px solid rgba(#bfbfbf, 0.2);
      margin-bottom: 8px;

      &:after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        bottom: 0px;
        width: 1px;
        height: calc(100% - 41px);
        background: rgba(#bfbfbf, 0.2);
      }

      span {
        width: 50%;
        padding-left: 8px;
        text-transform: uppercase;
        font-size: 10px;
      }

      b {
        width: 50%;
        padding-left: 8px;
        text-transform: uppercase;
        font-size: 10px;
        font-weight: normal;
      }
    }

    &__item {
      display: flex;
      transition: 0.3s;

      span {
        width: 50%;
        font-size: 14px;
      }

      b {
        width: 50%;
        font-size: 14px;
      }

      &.active {
        color: #91c258;
      }
    }
  }
  .tarrifs-price-download {
    display: flex;
    justify-content: center;
    padding-top: 24px;
    padding-bottom: 16px;

    span {
      color: #91c258;
      display: flex;
      line-height: 1;
      border-bottom: 1px solid #91c258;
      opacity: 0.8;
      font-weight: 700;
      font-family: "Helvetica Neue Roman";
      font-size: 14px;
    }
  }
  .tariffs-service {
    &__container {
      display: flex;
      justify-content: space-between;
      margin-left: -8px;
      margin-right: -8px;
      padding-bottom: 8px;
      flex-wrap: wrap;
    }

    padding-bottom: 10px;
    border: 1px solid #bfbfbf;
    border-radius: 4px;
    margin: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    text-align: center;
    position: relative;
    width: 28%;
    height: 130px;
    transition: 0.2s;
  }
  .tariffs-service__success-icon {
    right: 10px;
    top: 10px;
    width: 24px;
    height: 24px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: transparent;
    &:after {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      border: 2px solid #bfbfbf;
      border-radius: 50%;
      margin: auto;
      width: 20px;
      height: 20px;
      content: "";
      display: block;
    }
    img {
      opacity: 0;
    }
  }
  .isActive .tariffs-service__success-icon {
    opacity: 1;
    background: #91c258;
    img {
      opacity: 1;
    }
    &:after {
      border: 2px solid #91c258;
    }
  }
  .tariffs-service.isActive {
    border: 1px solid #91c258;

    .tariffs-service__cost {
      color: #efb443;
    }
  }
  .parcel-additional__content {
    display: flex;
    margin-left: -8px;
    margin-right: -8px;
    padding-top: 16px;
    padding-bottom: 16px;
  }
  .tariffs-service__title {
    max-width: 90px;
    text-align: center;
    font-size: 11px;
    color: #000000;
    margin-bottom: 4px;
    line-height: 1.4;
  }
  .tariffs-service__cost {
    color: #bfbfbf;
    font-size: 12px;
    font-family: "Helvetica Neue Medium";
    line-height: 14px;
    min-height: 15px;
  }
  .tariffs-service__img {
    margin-bottom: 4px;
  }
  .tariffs-service__img img {
    max-width: 100%;
  }
}
</style>
