<template>
  <label class="tariffs-settings__checkbox">
    <div class="tariffs-settings__checkbox-info">
      <div class="tariffs-settings__checkbox-title">
        {{ $t("tariffsBuyout1") }}
      </div>
      <div class="tariffs-settings__checkbox-text">
        <span v-if="isActive">${{ buyoutCost | numberRound }}</span>
        <span v-else>+{{ buyoutPercent }}% {{ $t("tariffsBuyout2") }}</span>
      </div>
    </div>
    <div class="switch-input">
      <input :checked="isActive" @input="onChange" type="checkbox" />
      <span></span>
    </div>
  </label>
</template>

<script>
import eventsRegister from "@/services/events-register";
export default {
  name: "TariffsOrder",
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    buyoutPercent: {
      type: Number,
      default: 7,
    },
    buyoutCost: {
      type: Number,
    },
  },
  methods: {
    onChange() {
      if (!this.isActive) {
        this.$root.$emit(eventsRegister.addMessage, {
          type: "success",
          message: this.$t("calcBuyoutMessage", { rate: this.buyoutPercent }),
        });
      }
      this.$emit("update:isActive", !this.isActive);
    },
  },
};
</script>

<style>
.tariffs-settings__checkbox {
  display: flex;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
}
.tariffs-settings__checkbox:active {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.tariffs-settings__checkbox-info {
  flex: 1 auto;
}
.tariffs-settings__checkbox-title {
  color: #696969;
  font-size: 16px;
  margin-bottom: 8px;
  line-height: 1;
}
.tariffs-settings__checkbox-text {
  color: #bfbfbf;
  font-size: 12px;
  line-height: 1;
}
.tariffs-settings__checkbox-switcher {
  position: relative;
  width: 50px;
  height: 28px;
  background: #696969;
  border-radius: 16px;
  transition: 0.3s;
}
.tariffs-settings__checkbox-switcher.is-active {
  background: #91c258;
}
.tariffs-settings__checkbox-switcher:after {
  content: "";
  position: absolute;
  background: #ffffff;
  border-radius: 5px;
  width: 6px;
  height: 18px;
  left: 12px;
  top: 0;
  bottom: 0;
  margin: auto;
  transition: 0.3s;
}
.tariffs-settings__checkbox-switcher.is-active:after {
  transform: translateX(20px);
}
.switch-input {
  cursor: pointer;
}
.switch-input input {
  display: none;
}
.switch-input input + span {
  width: 48px;
  height: 28px;
  border-radius: 14px;
  transition: all 0.3s ease;
  display: block;
  position: relative;
  background: #696969;
}
.switch-input input + span:before,
.switch-input input + span:after {
  content: "";
  display: block;
  position: absolute;
  transition: all 0.3s ease;
  box-sizing: border-box;
}
.switch-input input + span:before {
  top: 5px;
  left: 5px;
  width: 18px;
  height: 18px;
  border-radius: 9px;
  border: 5px solid #fff;
}
.switch-input input + span:after {
  top: 5px;
  left: 32px;
  width: 6px;
  height: 18px;
  border-radius: 40%;
  transform-origin: 50% 50%;
  background: #fff;
  opacity: 0;
}
.switch-input input + span:active {
  transform: scale(0.92);
}

.switch-input input:checked + span {
  background: #91c258;
}
.switch-input input:checked + span:before {
  width: 0px;
  border-radius: 3px;
  margin-left: 27px;
  border-width: 3px;
  background: #fff;
}
.switch-input input:checked + span:after {
  animation: blobChecked 0.35s linear forwards 0.2s;
}
.switch-input input:not(:checked) + span:before {
  animation: blob 0.85s linear forwards 0.2s;
}

@keyframes blob {
  0%,
  100% {
    transform: scale(1);
  }
  30% {
    transform: scale(1.12, 0.94);
  }
  60% {
    transform: scale(0.96, 1.06);
  }
}

@keyframes blobChecked {
  0% {
    opacity: 1;
    transform: scaleX(1);
  }
  30% {
    transform: scaleX(1.44);
  }
  70% {
    transform: scaleX(1.18);
  }
  50%,
  99% {
    transform: scaleX(1);
    opacity: 1;
  }
  100% {
    transform: scaleX(1);
    opacity: 0;
  }
}
</style>
