<template>
  <div class="tariff-result" @click="onOpenMore">
    <div class="tariff-result__service">
      <div class="tariff-result__title">{{ $t("customsServiceCalcP") }}</div>
      <div class="tariff-result__value">
        <i-count-up
          :endVal="serviceCost"
          :options="{
            duration: 1,
            decimalPlaces: 2,
          }"
        /><b>$</b>
      </div>
    </div>
    <div class="tariff-result__total">
      <div class="tariff-result__title">{{ $t("cardCheckTotal") }}</div>
      <div class="tariff-result__value">
        <i-count-up
          :endVal="totalCost"
          :options="{
            duration: 1,
            decimalPlaces: 2,
          }"
        /><b>$</b>
      </div>
    </div>
    <div class="tariff-result__icon">
      <img src="@/assets/img/tarif-check.svg" />
    </div>
  </div>
</template>

<script>
import ICountUp from "vue-countup-v2";

export default {
  name: "TariffsResult",
  props: {
    totalCost: Number,
    serviceCost: Number,
    onOpenMore: {
      type: Function,
      default: () => true
    },
  },
  components: {
    ICountUp,
  },
};
</script>

<style lang="scss">
.tariff-result {
  background: #333333;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: space-between;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 6px;
  padding-bottom: 6px;
  position: relative;
  &__title {
    color: #bfbfbf;
    font-size: 12px;
  }

  &__value {
    font-family: "OPTICalculator";
    font-size: 32px;
    color: #fff;
    display: flex;
    align-items: flex-start;
    line-height: 1;

    b {
      font-size: 20px;
      position: relative;
      margin-left: 4px;
      font-weight: normal;
    }
  }

  &__icon {
    width: 34px;
    height: 34px;
    background: #333333;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.28);
    display: flex;
    align-self: center;
    justify-content: center;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    bottom: -17px;
    border-radius: 50%;

    img {
      max-width: 20px;
      display: block;
    }
  }

  &__total {
    text-align: right;

    .tariff-result__value {
      justify-content: flex-end;
    }
  }

  &__service {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    .tariff-result__value {
      color: #ef8143;
      font-size: 22px;

      b {
        font-size: 14px;
      }
    }
  }
}
</style>