<template>
  <div class="tariffs-settings__tabs">
    <div class="tariffs-settings__tab is-active " v-if="currentTab === 284">
      <img src="@/assets/img/a-china.svg" />
      {{ $t("tarrifChinaToUsa") }}
      <span>${{ pricePerKG }} / 1 kg</span>
    </div>
    <div class="tariffs-settings__tab is-active " v-if="currentTab === 285">
      <!-- <img src="@/assets/img/a-usa.svg" /> -->
      <img src="@/assets/img/a-china.svg" />
      {{ $t("tarrifChinaToUa") }}
      <span>${{ pricePerKG }} / 1 kg</span>
    </div>
    <div class="tariffs-settings__tab is-active " v-if="currentTab === 301">
      <!-- <img src="@/assets/img/a-usa.svg" /> -->
      <img src="@/assets/img/a-china.svg" />
      {{ $t("tarrifChinaToUK") }}
      <span>${{ pricePerKG }} / 1 kg</span>
    </div>
    <div class="tariffs-settings__tab is-active " v-if="currentTab === 308">
      <!-- <img src="@/assets/img/a-usa.svg" /> -->
      <img src="@/assets/img/a-china.svg" />
      {{ $t("tarrifChinaToCanada") }}
      <span>${{ pricePerKG }} / 1 kg</span>
    </div>
    <!-- <div
      class="tariffs-settings__tab"
      @click="onChangeTab(294)"
      :class="{ 'is-active': currentTab === 294 }"
    >
      <img src="@/assets/img/a-turkey.svg" />
      {{ $t("turkey") }}
      <span>{{ deliveryRates.TK / 2 }} $ / 0.5 kg</span>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "TariiffsTabs",
  props: {
    currentTab: {
      type: Number,
      default: 287,
    },
    deliveryRates: {
      type: Object,
      default: () => ({
        USA: 0,
        CN: 0,
        // TK: 0,
      }),
    },
    pricePerKG: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    onChangeTab(v) {
      this.$emit("update:currentTab", v);
    },
  },
};
</script>

<style lang="scss">
.tariffs-settings__tabs {
  display: flex;
}
.tariffs-settings__tab {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #696969;
  background: #f1f1f1;
  // width: 50%;
  flex: 1 0 auto;
  height: 52px;
  box-sizing: border-box;
  border-bottom: 2px solid transparent;
  line-height: 1;
  padding-top: 2px;
  font-family: "Helvetica Neue Medium";
  box-shadow: 2px 0px 6px rgba(40, 40, 40, 0.06);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  position: relative;
  transition: 0.3s;

  img {
    position: absolute;
    right: 0;
    top: 0;
    filter: grayscale(1);
    opacity: 0.5;
    transition: 0.3s;
  }

  span {
    display: block;
    color: #bfbfbf;
    font-size: 12px;
    line-height: 12px;
    margin-top: 4px;
    font-weight: normal;
    font-family: "Helvetica Neue";
  }
}
.tariffs-settings__tab.is-active {
  border-bottom: 1px solid #91c258;
  background: #f8f8f8;
  box-shadow: 2px 0px 6px rgba(40, 40, 40, 0.06);
  z-index: 1;

  img {
    filter: grayscale(0);
    opacity: 1;
  }
}
</style>
