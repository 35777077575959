<template>
  <div class="tariffs-input">
    <div class="tariffs-input__top">
      <div class="tariffs-input__title">{{ title }}</div>
      <div class="tariffs-input__value" @click="onTapValue">
        <div v-if="value">
          <div v-if="min || max">
            $<span>{{ value }}</span>
          </div>
          <div v-else>
            <span>{{ weigthValue }}</span> {{ $t("kg") }}
          </div>
        </div>
        <b v-else>{{ $t("noway") }}</b>
      </div>
    </div>
    <div class="onboarding-slider__input" v-touch:swipe.self>
      <div v-if="max" class="tariffs-input__label">{{ min }}</div>
      <vue-slider
        v-if="min || max"
        :height="6"
        :tooltip="'none'"
        :value="value > max ? max : value"
        @change="onSilderChange"
        :drag-on-click="true"
        :min="min"
        :max="max"
      ></vue-slider>
      <vue-slider
        v-else
        :height="6"
        :tooltip="'none'"
        :value="value"
        @change="onWeightSilderChange"
        :drag-on-click="true"
        :data="sliderData"
        :adsorb="true"
        :included="true"
        :marks="[0, 0.1, 0.5, 1, 2, 5, 10, 20, 30, 50]"
      ></vue-slider>
      <div v-if="max" class="tariffs-input__label">{{ max }}+</div>
      <div v-if="!max" class="tariffs-input__weigth">
        <img src="@/assets/img/subtract.svg" />
      </div>
    </div>
  </div>
</template>

<script>
import eventsRegister from "@/services/events-register";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";

export default {
  name: "TariffsInput",
  components: { VueSlider },
  props: {
    title: {
      type: String,
      default: "",
    },
    value: {
      type: Number,
      default: 0,
    },
    sliderData: {
      type: Array,
      default: () => [],
    },
    sliderMarks: {
      type: Array,
      default: () => [],
    },
    onTapValue: {
      type: Function,
      default: () => true,
    },
    minWeigthCost: Number,
    min: Number,
    max: Number,
  },
  computed: {
    weigthValue() {
      console.log("this.value");
      return this.value;
      // return this.value < 0.5 ? 0.5 : this.value
    },
  },
  methods: {
    onSilderChange(v) {
      this.$emit("update:value", v);
    },
    onWeightSilderChange(v) {
      if (!v) {
        this.$root.$emit(eventsRegister.addMessage, {
          type: "success",
          message: `${this.$t("calcMinWeightCost")} $${this.minWeigthCost}`,
        });
      }
      this.$emit("update:value", v);
    },
  },
};
</script>

<style lang="scss">
.tariffs-input {
  padding-top: 10px;
  padding-bottom: 1px;
  width: 100%;
  box-sizing: border-box;

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__title {
    color: #282828;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    font-family: "Helvetica Neue Medium";
  }

  &__value {
    height: 30px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border: 1px solid #f1f1f1;
    border-radius: 4px;
    color: #bfbfbf;
    font-size: 16px;
    min-width: 80px;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 2px;
    font-family: "Helvetica Neue Medium";

    span {
      color: #91c258;
      font-weight: 500;
      padding-right: 2px;
    }

    b {
      font-weight: 500;
      font-family: "Helvetica";
      margin-top: -2px;
    }
  }

  .onboarding-slider__input {
    position: relative;
    margin-left: 16px;
    margin-right: 16px;
    max-width: 100%;
  }
  .onboarding-slider__input .vue-slider-process {
    background: #91c258;
  }
  .onboarding-slider__input .vue-slider-rail {
    background: #f1f1f1;
  }
  .onboarding-slider__input .vue-slider-dot-handle:after {
    background: #91c258;
  }
  .onboarding-slider__input .vue-slider-ltr .vue-slider-mark-label {
    color: #bfbfbf;
  }
  .onboarding-slider__input .vue-slider-mark-step {
    background: #f1f1f1;
    top: 0;
    bottom: 0;
    margin: auto;
  }
  .onboarding-slider__input .vue-slider-mark-step.vue-slider-mark-step-active {
    background: #91c258;
  }
  .onboarding-slider__input .vue-slider-process {
    background: #91c258;
  }
  .onboarding-slider__input .vue-slider-rail {
    background: #f1f1f1;
  }
  .onboarding-slider__input .vue-slider-dot-handle:after {
    background: #91c258;
  }
  .onboarding-slider__input .vue-slider-ltr .vue-slider-mark-label {
    color: #bfbfbf;
  }
  .onboarding-slider__input .vue-slider-mark-step {
    background: #f1f1f1;
  }
  .onboarding-slider__input .vue-slider-mark-step.vue-slider-mark-step-active {
    background: #91c258;
  }

  &__label {
    position: absolute;
    top: 10px;
    color: #bfbfbf;
    font-size: 12px;

    &:first-child {
      left: -5px;
      &:after {
        width: 6px;
        height: 6px;
        background: #91c258;
        border-radius: 50%;
        display: block;
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 31px;
      }
    }
    &:last-child {
      right: -10px;
      &:after {
        width: 6px;
        height: 6px;
        background: #f1f1f1;
        border-radius: 50%;
        display: block;
        content: "";
        position: absolute;
        right: 8px;
        top: 31px;
      }
    }
  }

  &__weigth {
    position: absolute;
    top: 10px;
    left: -8px;
    background: #fff;
    z-index: 10;
  }
}
</style>
